
import React, { forwardRef, useImperativeHandle, useState } from "react";
import "../../scss/popup.scss";
import { isValidEmail } from '../../utils/utils';
import CustomPopup from './custom-popup';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const WebinarPopup = forwardRef((props, ref) => {
    const [show, setShow] = React.useState(false);
    const [showForm, setShowForm] = useState(true);
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const popupCloseHandler = (e) => {
        setShow(false);
    };
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        close() {
            setShow(false);
        },

        open(email = '') {
            if (email) {
                setEmail(email);
            }
            setShow(true);
        }
    }));

    return (
        <>
            <CustomPopup
                onClose={popupCloseHandler}
                show = {show}
                title="">
                <div class="_form-thank-you">
                    <h3 className='text-center'>Exclusive Webinar Series</h3>
                    <p className='text-center'>
                    Exclusive Webinar Series. Cose: $0 (Free) Limited Seats Available. Don't 
                    miss the opportunity. Register Now
                    </p>
                    <p className='text-center'>
                        <a href="/webinar" className="btn btn-warning">Click To Subscribe</a>
                    </p>
                </div>
            </CustomPopup>
        </>
    )
});

export default WebinarPopup;