import dayjs from "dayjs";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState, useRef } from 'react';
import { addSubcriber, addAndRemoveDisabledCls, isValidEmail } from '../utils/utils';
import { submitInventivContactForm } from '../utils/campaign';
import "react-multi-carousel/lib/styles.css";
import Seo from "../components/seo";
import ContactPopup from '../components/Popup/contact-popup';
import WebinarPopup from '../components/Popup/webinar-popup';
//
const getdata = graphql`
    {
      wpgraphql {
        posts(first: 3) {
          nodes {
            id
            uri
            title
            seo {
                metaDesc
                focuskw
            }
            slug
            date
            featuredImage {
              node {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  `
const Home = () => {
  const data = useStaticQuery(getdata);
  const posts = data.wpgraphql.posts;
  //
  const childRef = useRef();
  const openPopup = () => {
    childRef.current?.open();
  }
  //
  const webinarPopupRef = useRef();
  const openWebinarPopup = () => {
    webinarPopupRef.current?.open();
  }
  //
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [invitedEmail, setInvitedEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  //
  
  //
  const submit = async (e) => {
    e.preventDefault();

    if (firstName && lastName && email && isValidEmail(email)) {
        // call api
        const data = {
            email: email,
            name: `${firstName} ${lastName}`,
            phone: '',
            subject: '[Inventiv] New sign up from ' + email,
            message: `New sign up from  ${email}`,
            site: 'inventiv.org'
        }

        // submit activeCampaign
        submitInventivContactForm(data);

        //
        fetch("https://api.trustyip.com/subcribers/add", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json"
        },
        "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {
            alert('Thank you for contacting us. We will follow up with you soon');
            // reset
            setEmail('');
            setFirstName('');
            setLastName('');
            setEmailSent(true);
        })
        .catch(err => {
            alert('There is something wrong. Please try again later!');
            console.log(err);
        });
        //
        addAndRemoveDisabledCls('signUpBtn');
    } else {
        alert('Please enter your valid email.');
    }

  }

  const inviteSubcriber = (e) => {
    e.preventDefault();
    if (invitedEmail && isValidEmail(invitedEmail)) {
      try {
        const data = {
          email: invitedEmail,
          name: `${invitedEmail}`,
          phone: '',
          subject: '[Inventiv] New subcribe from ' + invitedEmail,
          message: `New subcribe from  ${invitedEmail}`,
          site: 'inventiv.org'
        }
        const result = addSubcriber(data);
        alert('Thank you for contacting us. We will follow up with you soon');
        setInvitedEmail('');
      } catch(err) {
        alert('There is something wrong. Please try again later!');
        console.log(err);
      };
    } else {
      alert('Please enter your valid email.');
    }
  }
  //
  useEffect(() => {
    // openWebinarPopup();
  }, []);
  return (
    <>
      <Seo title="Home" seo={{}} />

      {/* <!-- Banner start --> */}
      <div class="banner banner-2" id="banner">
        <div class="container">
          <div class="row flex-column-reverse flex-lg-row">
            <div class="col-12 col-md-6 b-img mt-3">
              <img src="assets/img/avatar/man-1.webp" alt="" />
            </div>
            <div class="col-12 col-md-6 banner-col-2 mt-3">
              <h1 class="heading-title">IP</h1>
              <h1 class="heading-desc">RIGHTS FOR INNOVATORS</h1>
              <p class="heading-brief">
                Promoting the role of intellectual property rights in technology and the arts through awareness
                and tools for inventors.
              </p>

            </div>
          </div>
          <h1 class="heading-signup-text">SIGN UP FOR THE <span class="d-clr">FREE</span> PROVISIONAL PATENT WRITING
            SOFTWARE</h1>
        </div>
      </div>

      {/* <!-- banner end --> */}
      <section class="banner-form">
        <div class="container">
          <div class="outer-box theme-card ">
            <h6 class="text-left">Save your time. Save your money. Bring your idea to life
            </h6>
            <div class="time-counter mt-3">
              <form>
                <div class="form-row row align-items-center">

                  <div class="col-md-3 my-1">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-user d-clr"></i></div>
                      </div>
                      <input type="text" class="form-control" id="inlineFormInputGroupUsername"
                        placeholder="Your First Name" required  value={firstName} onChange={e => setFirstName(e.target.value)}/>
                    </div>
                  </div>
                  <div class="col-md-3 my-1">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-user d-clr"></i></div>
                      </div>
                      <input type="text" class="form-control" id="inlineFormInputGroupUsername"
                        placeholder="Your Last Name" required  value={lastName} onChange={e => setLastName(e.target.value)}/>
                    </div>
                  </div>
                  <div class="col-md-3 my-1">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-envelope d-clr"></i></div>
                      </div>
                      <input type="text" class="form-control" id="inlineFormInputGroupUsername"
                        placeholder="Your Email" required  value={email} onChange={e => setEmail(e.target.value)}/>
                    </div>
                  </div>

                  <div class="btn-box col-md-3 ">
                    <input type="button" class="btn btn-default" id="signUpBtn" value="Sign Up Now"  onClick={submit}/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- About start --> */}
      <section class="pb-3 mb-3 pt-3 about-section" id="who-we-are">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 wow fadeInLeft delay-04s">
              <h1 class="title sec-clr bold">Welcome To Inventiv.org</h1>
              <p class="sec-clr f-16">Many inventors want to protect their huge ideas with the patent system. Yet
                the cost of patenting can be daunting. To make money with your idea, you must have patent
                protection.
                <br />
                Inventive is a non-profit that helps inventors cross this bridge with our educational materials
                and software. Are you curious about the next step?
              </p>
              <a href="/what-we-do" class="d-clr bold">Read more</a>
            </div>
            <div class="col-12 col-md-8">
              <div class="row">
                <div class="col-12 col-md-6 wow fadeInDown delay-04s">
                  <h2 class="sec-clr bold">About <br /> Inventiv.org</h2>
                  <p class="sec-clr">
                    At Inventiv.org, we are a passionate global community united by a shared vision: to
                    unleash the power of invention and drive positive change. We believe in the
                    extraordinary potential of human creativity to transform our world, and we are dedicated
                    to nurturing and empowering the inventors, innovators, and dreamers who dare to imagine
                    a better future.
                  </p>
                </div>

                <div class="col-12 col-md-6 wow fadeInRight delay-04s">
                  <h2 class="sec-clr bold">Our <br />mission</h2>
                  <p class="sec-clr">
                    Our mission is to inspire, connect, and support the next generation of inventors. We
                    strive to create an environment where ideas flourish, where diverse perspectives
                    converge, and where innovation thrives. We believe that by harnessing the collective
                    brilliance of our community, we can tackle the most pressing challenges facing our
                    planet.
                  </p>
                </div>
                <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                  <h2 class="sec-clr bold">Why it’s <br />
                    more than a platform</h2>
                  <p class="sec-clr">
                    Inventiv.org is more than a platform; it’s a movement. Together, we strive for a future
                    that is sustainable, equitable, and prosperous for all. We are inspired by the wonders
                    of nature & motivated by the urgent need to protect and preserve our planet. We believe
                    that through invention and innovation, we can create a world where technology and nature
                    coexist harmoniously.
                  </p>
                </div>
                <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                  <h2 class="sec-clr bold">Join us at <br /> Inventiv.org</h2>
                  <p class="sec-clr">
                    Join us at Inventiv.org and become part of a global network of changemakers who are
                    shaping the world through invention. Together, we can push the boundaries of what’s
                    possible, solve complex problems, and build a future that is bright and full of promise.
                    Together, let’s unleash the power of invention and forge a path toward a better
                    tomorrow.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* <!-- about end --> */}

      {/* <!-- introduction --> */}
      <section class="mb-3 introduction-section pt-6">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 wow fadeInLeft delay-04s">
              <h1 class="title sec-clr bold">
                A Cloud-Based <br />
                <span class="d-clr">Patent Writing Software</span> <br />
                For American Inventors
              </h1>
              <p class="sec-clr w-85">
                Whether you’re an inventor looking for the fastest way to get your patent, or a small business
                owner considering filing a patent application, a provisional patent may be right for you. Not
                only is it much cheaper than a full patent application, but it can also save you a lot of time.
                Unlike a non-provisional patent application, a provisional patent application is a simple
                process that doesn’t require an attorney. In fact, many people file their own provisional
                patents.
              </p>
              <a href="#" onClick={openPopup} class="btn btn-default mb-3">Sign
                up Now</a>
            </div>
            <div class="col-12 col-md-6 wow fadeInRight delay-04s">
              <div class="thumbnail">
                <img style={{ cursor: 'pointer' }} src="assets/img/banner/inventiv_cover.png" alt="Thumbnail Image"
                  class="img-fluid" id="videoThumbnail" data-bs-toggle="modal" data-bs-target="#videoModal" />
              </div>
              <div class="businessImage text-center mt-1">
                <img src="assets/img/avatar/business.jpg" class="img-fluid" alt="" />
              </div>
            </div>
            {/* <!-- Video Modal --> */}
            <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content" style={{ border: 'none', background: 'transparent' }}>
                  <div class="modal-body" style={{ padding: 0 + 'px' }}>
                    {/* <!-- Embedded YouTube Video --> */}
                    <video class="" id="videoPlayer" width="100%" controls="" preload="metadata"
                      controlslist="nodownload">
                      <source src="assets/video/inventiv-ad-2.mp4" type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 offset-md-3 mt-10 wow fadeInUp delay-04s">
              <div class="theme-card">
                <h6>
                  <strong> Inventiv Announces Early Access To Its
                    Online Provisional Filing Tools For Individual Inventors </strong>
                </h6>
                <h6 class="sec-clr">
                  <strong> Beta Testers Are Invited </strong>
                </h6>
                <form action="">
                  <div class="row">
                    <div class="col-12 col-md-8 my-1">
                      <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-envelope d-clr"></i></span>
                        <input type="text" class="form-control" placeholder="Your Email" required value={invitedEmail} onChange={e => setInvitedEmail(e.target.value)} />
                      </div>
                    </div>
                    <div class="btn-box col-12 col-md-3 my-1">
                      <input type="button" class="btn btn-default" value="Subscribe" onClick={inviteSubcriber}/>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- introducton end --> */}

      {/* <!-- patenting process--> */}
      <section class="mb-3 mt-6 pt-6 pb-6 patenting-process">
        <div class="container">
          <h1 class="text-center w-clr wow fadeInDown delay-04s">Patenting <span class="d-clr">Process</span></h1>
          <p class="text-center w-clr">
            Follow these links to learn more about patenting process
          </p>
          <div class="row d-flex justify-content-between mt-3 wow fadeInUp delay-04s">
            <div class="col-12 col-md-2 text-center mt-3">
              <img src="assets/img/logos/idea-lamp-marketing_svgrepo.com_.svg" width="70px" alt="" />
              <h4 class="w-clr mt-1">Patent <br /> Basic</h4>
              <a href="/patentfaqs/patent-basics/" class="d-clr">Read More</a>
            </div>
            <div class="col-12 col-md-2 text-center mt-3">
              <img src="assets/img/logos/et_puzzle.svg" width="70px" alt="" />
              <h4 class="w-clr mt-1">Provisional Application</h4>
              <a href="/patentfaqs/provisional-patent-application/" class="d-clr">Read More</a>
            </div>
            <div class="col-12 col-md-2 text-center mt-3">
              <img src="assets/img/logos/chart-graph-data-5_svgrepo.com_.svg" width="70px" alt="" />
              <h4 class="w-clr mt-1">Utility Application</h4>
              <a href="/patentfaqs/utility-patent-application/" class="d-clr">Read More</a>
            </div>
            <div class="col-12 col-md-2 text-center mt-3">
              <img src="assets/img/logos/exam_svgrepo.com_.svg" width="70px" alt="" />
              <h4 class="w-clr mt-1">Patent Examination</h4>
              <a href="/patentfaqs/respond-to-office-actions/" class="d-clr">Read More</a>
            </div>
            <div class="col-12 col-md-2 text-center mt-3">
              <img src="assets/img/logos/circum_umbrella.svg" width="70px" alt="" />
              <h4 class="w-clr mt-1">Patent Issurance</h4>
              <a href="/patentfaqs/respond-to-office-actions/" class="d-clr">Read More</a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- patenting process--> */}

      <section class="mt-3 mb-3 how-to-help" id="how-to-help">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-3 wow fadeInLeft delay-04s">
              <h1 class="title sec-clr bold">How to Help</h1>
              <p class="sec-clr f-16">
                At Inventiv.org, we believe that making a difference starts with collective action. We invite
                you to join us in our mission to inspire and empower inventors to create a better world. Your
                support can play a vital role in nurturing innovation, fostering creativity, and driving
                positive change. Here are a few ways you can help:
              </p>
              <a href="/contact" class="btn btn-default">Request a Meeting</a>
            </div>
            <div class="col-12 col-md-9">
              <div class="row">
                <div class="col-12 col-md-4 wow fadeInUp delay-04s">
                  <h2 class="title sec-clr bold">Donate</h2>
                  <p class="sec-clr">
                    As a non-profit 501(c)(3) organization, we rely on the generosity of supporters like
                    you. Your financial contribution can make a meaningful impact by enabling us to expand
                    our programs, provide resources to inventors, and support groundbreaking inventions that
                    address global challenges. Every donation, no matter the size, helps fuel the inventors’
                    journey and contributes to a brighter future.
                  </p>
                </div>
                <div class="col-12 col-md-4 wow fadeInUp delay-04s">
                  <h2 class="title sec-clr bold">Volunteer</h2>
                  <p class="sec-clr">
                    Share your skills, expertise, and time to make a difference. We welcome volunteers who
                    can offer mentorship, lead workshops, or provide support in various areas such as event
                    planning, marketing, and fundraising. By becoming a volunteer, you can directly engage
                    with inventors, inspire creativity, and help shape the next generation of innovators.
                  </p>
                </div>
                <div class="col-12 col-md-4 wow fadeInRight delay-04s">
                  <h2 class="title sec-clr bold">Spread the Word</h2>
                  <p class="sec-clr">
                    Help us amplify our message and reach a wider audience. Share our stories, initiatives,
                    and success stories through social media, word of mouth, or by organizing local events.
                    By spreading awareness about the power of invention and the resources available at
                    Inventiv.org, you can inspire others to get involved and make a positive impact.
                  </p>
                </div>
                <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                  <h2 class="title sec-clr bold">Collaborate</h2>
                  <p class="sec-clr">
                    We believe that innovation thrives when diverse perspectives come together. If you or
                    your organization shares our passion for invention and impact, let’s collaborate!
                    Together, we can create powerful partnerships, drive systemic change, and support
                    inventors in solving complex problems.
                  </p>
                </div>
                <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                  <h2 class="title sec-clr bold">Support Inventors</h2>
                  <p class="sec-clr">
                    Celebrate the inventors in your community and beyond. Champion their ideas, collaborate
                    with them, or offer resources and expertise to help bring their inventions to life. By
                    supporting inventors, you contribute to a culture of innovation and pave the way for
                    groundbreaking solutions.
                  </p>
                </div>

              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <h5 class="text-center wow fadeInDown delay-04s">
                Join us at Inventiv.org and discover how you can make a lasting impact. Together, we can foster
                a global community of inventors who shape the future through their creativity, determination,
                and dedication.
              </h5>
            </div>
          </div>
        </div>
      </section>

      <section class="where-we-work mt-3 mb-3" id="where-we-work">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4 wow fadeInLeft delay-04s">
              <img src="assets/img/banner/where-we-work.jpg" width="100%" alt="" />
            </div>
            <div class="col-12 col-md-8">
              <div class="row">
                <div class="col-12 col-md-12 wow fadeInDown delay-04s">
                  <h1 class="sec-clr">Where We Work</h1>
                  <p class="sec-clr f-16">
                    At Inventiv.org, our mission knows no boundaries. We work tirelessly to empower
                    inventors and foster innovation across the globe. From bustling urban centers to remote
                    communities, our reach extends far and wide. Here’s a glimpse into where we work
                  </p>
                </div>
                <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                  <h2 class="sec-clr">
                    Local Communities
                  </h2>
                  <p class="sec-clr">
                    We believe that innovation flourishes in local communities. We actively engage with
                    inventors, educators, and organizations at the grassroots level to provide resources,
                    support, and inspiration. By working closely with local communities, we empower
                    inventors to create solutions that address the unique challenges they face, whether it’s
                    in healthcare, education, agriculture, or beyond.
                  </p>
                </div>
                <div class="col-12 col-md-6 wow fadeInUp delay-04s">
                  <h2 class="sec-clr">
                    Innovation Programs
                  </h2>
                  <p class="sec-clr">
                    We collaborate with inventors and organizations at the national level to drive systemic
                    change. Through partnerships and strategic alliances, we support large-scale innovation
                    programs, policy advocacy, and capacity building efforts. By working with governments,
                    institutions, and industry leaders, we aim to create an enabling environment for
                    inventors to thrive and transform their nations.
                  </p>
                </div>

              </div>

            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-4 wow fadeInLeft delay-04s">
              <h2 class="sec-clr">
                Global Networks
              </h2>
              <p class="sec-clr">
                Innovation knows no borders, and neither do we. We foster a global network of inventors,
                experts, and organizations that span continents and cultures. By connecting inventors from
                diverse backgrounds and facilitating cross-cultural collaborations, we unleash the power of
                collective genius. Through our global initiatives, we aim to address global challenges and
                create sustainable solutions that transcend geographical boundaries.
              </p>
            </div>
            <div class="col-12 col-md-4 wow fadeInUp delay-04s">
              <h2 class="sec-clr">
                Underserved Communities
              </h2>
              <p class="sec-clr">
                We are deeply committed to ensuring that innovation reaches all corners of society. We
                prioritize working with underserved communities, where access to resources and opportunities may
                be limited. By providing educational programs, mentorship, and funding support, we strive to
                empower inventors in these communities to create solutions that improve lives and promote social
                equity.
              </p>
            </div>
            <div class="col-12 col-md-4 wow fadeInRight delay-04s">
              <h2 class="sec-clr">
                Collaborative Spaces
              </h2>
              <p class="sec-clr">
                Innovation thrives in spaces that encourage collaboration and creativity. We establish
                collaborative hubs, innovation centers, and maker spaces where inventors can come together,
                exchange ideas, and prototype their inventions. These physical and virtual spaces serve as
                catalysts for innovation, providing inventors with the infrastructure and tools they need to
                bring their ideas to fruition.
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <p class="text-center wow fadeInDown delay-04s">
                At Inventiv.org, our impact knows no borders. We believe that the power of invention can
                transform lives and shape a better future, regardless of where we work. Together, let’s break
                barriers, bridge divides, and create a global community of inventors that drives positive change
                on a local, national, and international scale
              </p>
            </div>
            <div class="col-12 mt-3">
              <h5 class="text-center wow fadeInDown delay-04s">
                Join us at Inventiv.org and be a part of our global mission. Together, we can unleash the
                potential of invention to create a world that is innovative, sustainable, and full of
                possibilities.
              </h5>
            </div>
            <div class="col-12 col-md-6 offset-md-3 mt-3">
              <div class="theme-card wow fadeInUp delay-04s">
                <h6>
                  <strong> Inventiv Announces Early Access To Its
                    Online Provisional Filing Tools For Individual Inventors </strong>
                </h6>
                <h6 class="sec-clr">
                  <strong> Beta Testers Are Invited </strong>
                </h6>
                <form action="">
                  <div class="row">
                    <div class="col-12 col-md-8 my-1">
                      <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-envelope d-clr"></i></span>
                        <input type="text" class="form-control" placeholder="Your Email" required="" />
                      </div>
                    </div>
                    <div class="btn-box col-12 col-md-3 my-1">
                      <input type="submit" class="btn btn-default" value="Subscribe" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="how-we-help-you mt-10 mb-3">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-7 pt-3 wow fadeInLeft delay-04s">
              <h1 class="sec-clr">
                How We <br />
                <span class="f-60">Help You</span>
              </h1>
              <div class="icon-list-first">
                <ul>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>

                    </span>
                    <span>
                      Educate inventors about the economic value of IP protection
                    </span>
                  </li>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>
                    </span>
                    <span className="pe-1">
                      Enhance the ability of creative artists to protect and monetize their works through
                      free resources and software
                    </span>
                  </li>
                </ul>
              </div>
              <div class="icon-list-last">
                <ul>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>
                    </span>
                    <span className="pe-1">
                      Support academic institutions, independent inventors, and small businesses in
                      commercializing their inventions through the IP system through our free resources
                      and software.
                    </span>
                  </li>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>
                    </span>
                    <span>
                      Conduct research on the economic impact of intellectual property rights.
                    </span>
                  </li>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>
                    </span>
                    <span>
                      Guide innovators on using IP rights efficiently and effectively
                    </span>
                  </li>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>
                    </span>
                    <span>
                      Provide tools and software that demystify IP right creation for creators and
                      innovators
                    </span>
                  </li>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>
                    </span>
                    <span>
                      Expand opportunities for all people to harness their creativity and innovations
                    </span>
                  </li>
                  <li class="d-flex">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                        fill="none">
                        <path
                          d="M12 21C17.5228 21 22 16.5228 22 11C22 5.47715 17.5228 1 12 1C6.47715 1 2 5.47715 2 11C2 16.5228 6.47715 21 12 21Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M18.5 11C18.5 7.15 15.2 4.1 11.25 4.55C8.25 4.9 5.85 7.3 5.55 10.3C5.3 12.6 6.25 14.65 7.85 15.95C8.55 16.55 9 17.4 9 18.35V18.5H15V18.45C15 17.55 15.4 16.65 16.1 16.05C17.55 14.85 18.5 13.05 18.5 11Z"
                          fill="#FBC02D"></path>
                        <path
                          d="M15.3 10.0999L13.8 9.0999C13.65 8.9999 13.4 8.9999 13.25 9.0999L12 9.8999L10.8 9.0999C10.65 8.9999 10.4 8.9999 10.25 9.0999L8.75 10.0999C8.65 10.1999 8.55 10.2999 8.55 10.4499C8.55 10.5999 8.55 10.7499 8.65 10.8499L10.55 13.1999V18.4999H11.55V12.9999C11.55 12.8999 11.5 12.7999 11.45 12.6999L9.8 10.6499L10.55 10.1499L11.75 10.9499C11.9 11.0499 12.15 11.0499 12.3 10.9499L13.5 10.1499L14.25 10.6499L12.6 12.6999C12.55 12.7999 12.5 12.8999 12.5 12.9999V18.4999H13.5V13.1999L15.4 10.8499C15.5 10.7499 15.55 10.5999 15.5 10.4499C15.45 10.2999 15.4 10.1499 15.3 10.0999Z"
                          fill="#FFF59D"></path>
                        <path
                          d="M12 23.5C12.8284 23.5 13.5 22.8284 13.5 22C13.5 21.1716 12.8284 20.5 12 20.5C11.1716 20.5 10.5 21.1716 10.5 22C10.5 22.8284 11.1716 23.5 12 23.5Z"
                          fill="#5C6BC0"></path>
                        <path
                          d="M13 22.5H11C9.9 22.5 9 21.6 9 20.5V18H15V20.5C15 21.6 14.1 22.5 13 22.5Z"
                          fill="#9FA8DA"></path>
                        <path
                          d="M15 20.5L9.2 21.3C9.35 21.65 9.65 22 10 22.2L14.7 21.55C14.9 21.25 15 20.9 15 20.5ZM9 19.35V20.35L15 19.5V18.5L9 19.35Z"
                          fill="#5C6BC0"></path>
                      </svg>
                    </span>
                    <span class="">
                      Assist in the economic development of new ideas and expand the global IP systems.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12 col-md-5 wow fadeInRight delay-04s">
              <div class="img-absolute">
                <img src="assets/img/banner/i5.jpg" class="mb-1" alt="" />
              </div>
              <div class="img-block">
                <img src="assets/img/banner/i2.jpg" class="mb-1" width="100%" alt="" />
              </div>
              <div class="img-block">
                <img src="assets/img/banner/i3.jpg" class="mb-1" alt="" />
              </div>
              <div class="img-absolute-2">
                <img src="assets/img/banner/i4.jpg" class="mb-1" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- as seen start --> */}
      <div class="gift-registration content-area-2 ">
        <div class="container">
          <div class="wow fadeInDown delay-04s mb-3">
            <h4 class="title"><strong>As seen on</strong></h4>
          </div>
          <div class="row wow fadeInUp delay-04s">
            <div class="multi-carousel" data-items="1,3,5,6" data-slide="1" id="multiCarousel" data-interval="1000">
              <div class="multi-carousel-inner">
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/townhallfinance-1.jpg" alt="brand" />
                  </div>
                </div>
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/marketwatch-1.jpg" alt="brand" />
                  </div>
                </div>
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/prweb-150x49-1.jpg" alt="brand" />
                  </div>
                </div>
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/spoke-150x49-1.jpg" alt="brand" />
                  </div>
                </div>
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/yahoofinance-150x49-1.jpg" alt="brand" />
                  </div>
                </div>
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/2news-1.jpg" alt="brand" />
                  </div>
                </div>
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/prweb-150x49-1.jpg" alt="brand" />
                  </div>
                </div>
                <div class="item">
                  <div class="pad15">
                    <img src="assets/img/brands/spoke-150x49-1.jpg" alt="brand" />
                  </div>
                </div>
              </div>
              {/* <!-- <a class="multi-carousel-indicator leftLst" aria-hidden="true">
                    <i class="fa fa-angle-left"></i>
                  </a>
                  <a class="multi-carousel-indicator rightLst" aria-hidden="true">
                    <i class="fa fa-angle-right"></i>
                  </a> --> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- as seen end --> */}
      {/* <!-- Lastest news --> */}
      <div class="wedding-events content-area" id="events">
        <div class="container">
          <div class="wow fadeInDown delay-04s">
            <h1 class="text-center sec-clr mb-3">Latest News</h1>
          </div>
          <div class="row">
            {
              posts.nodes.map(data => {
                return (
                  <div class="col-lg-4 wow fadeInLeft delay-04s">
                    <div class="wedding-events-box">
                      <div class="photo">
                        <a href={"/blog/" + data.slug}>
                          <img src={data.featuredImage?.node?.mediaItemUrl} alt="img" class="img-fluid" />
                        </a>
                      </div>
                      <div class="detail">
                        <p class="sec-clr">{dayjs(data.date).format('MMM DD, YYYY')}</p>
                        <h3><a href={"/blog/" + data.slug}>{data.title}</a></h3>
                        {/* <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce urna urna, imperdiet et purus ut, ultricies sollicitudin arcu.</p> --> */}
                        <a href={"/blog/" + data.slug} class="read-more">Read more</a>
                      </div>
                    </div>
                  </div>
                )
              }
              )}
          </div>
        </div>
      </div>
      {/* <!-- latest news end --> */}
      <ContactPopup ref={childRef}></ContactPopup>
      <WebinarPopup ref={webinarPopupRef}></WebinarPopup>
    </>
  )
}
export default Home